import React, { ChangeEvent, useEffect, useState } from 'react';
import './ticket-payment-tab.scoped.scss';
import { Trans, useTranslation } from 'react-i18next';
import { TicketContext } from '../../store';
import { Box, Button, Checkbox, Grid } from '@material-ui/core';
import PaymentBox from '../pyment-box/payment-box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TicketCardPreview } from '../ticket-card-preview/ticket-card-preview';
import {
  PaymentTypeData,
  PaymentTypesData,
  paymentTypesToDisplayData,
} from '../../statics/payment-types';
import { PaymentService } from '../../services/payment/payment.service';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { currency } from '../../statics/main';
// import { history } from 'react-router-guard';
import ErrorBox from '../error-box/error-box';
import {
  formatCurrency,
  handleSessionExpire,
  isIosDevice,
  isSessionExpired,
  scrollToTabs,
} from '../../helpers';
import { LangService } from '../../services/lang/lang.service';
import { config } from '../../config';
import { TicketsService } from '../../services/tickets/tickets.service';
import { Payment } from '../../types/Payment';
import { SEPA_months } from '../../statics/payment-types';
declare const $: any;

export const TicketPaymentTab = () => {
  const [t] = useTranslation();
  const {
    setActiveTab,
    activeTab,
    paymentTypes,
    paymentTypesLoaded,
    paymentErrorData,
    setPaymentSuccess,
    setPaymentSuccessData,
    setOrderNumber,
    orderNumber,
    setOrderToken,
    orderToken,
    isTabDisabled,
    selectedPrice,
    selectedCoreZone,
    isExistingOrder,
  } = React.useContext(TicketContext);
  const paymentService = new PaymentService();
  const ticketsService = new TicketsService();
  const sessionStorageService = new SessionStorageService();
  const langService = new LangService();
  const lang = langService.getCurrentLang();
  const sessionID = sessionStorageService.getData('session');
  // Amount in EUR so divide by 100
  const amount =
    (selectedPrice.priceCardWithoutCZ +
      (selectedCoreZone.czFrom === 'ja' ? selectedPrice.priceCZFrom : 0) +
      (selectedCoreZone.czTo === 'ja' ? selectedPrice.priceCZTo : 0)) /
    100;
  const [
    activePaymentMethod,
    setActivePaymentMethod,
  ] = useState<PaymentTypeData>({} as PaymentTypeData);
  const [checkoutID, setCheckoutID] = useState<string>('');
  const [paymentError, setPaymentError] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isPaymentSaving, setPaymentSaving] = useState(false);
  const [submitPaymentForm, setSubmitPaymentForm] = useState(false);
  const [paymentTypesToDisplay, setPaymentTypesToDisplay] = useState(
    paymentTypesToDisplayData
  );
  const [ipAddress, setIpAddress] = useState('');

  const backToPayment = async () => {
    setCheckoutID('');
    await continueExistingOrder(orderToken);
  };

  const back = () => {
    if (isTabDisabled) {
      // go to user data tab.
      setActiveTab(1);
      scrollToTabs();
      // setTimeout(() => {
      //   history.replace(`/?sprache=${lang}`);
      //   sessionStorageService.clearAll();
      //   window.location.reload();
      // }, 1000);
    } else {
      setActiveTab(activeTab - 1);
      scrollToTabs();
    }
  };

  const remvoveScript = () => {
    const element: any = document.getElementById('paymentCheckout');
    if (element) {
      element.parentNode.removeChild(element);
    }
  };

  const setPaymentScript = (checkoutID: string) => {
    remvoveScript();
    const win: any = window;
    try {
      win.wpwlOptions = {
        locale: lang,
      };
    } catch (error) {
      console.log(error);
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${config.paymentGatewayURL}v1/paymentWidgets.js?checkoutId=${checkoutID}`;
    script.id = 'paymentCheckout';
    document.body.appendChild(script);
  };

  const setPaymentData = async () => {
    setSubmitPaymentForm(true);
    if (!activePaymentMethod?.name || !termsAccepted || isPaymentSaving) {
      return;
    }
    setPaymentError('');
    setPaymentSaving(true);
    const selectedPayment: Payment = paymentTypes.filter(
      (item: Payment) => item.name === activePaymentMethod.value
    )[0];
    // set payment name to check for SEPA status if payment was SEPA.
    sessionStorageService.setData('paymentValue', selectedPayment.name);
    // convert keys first char to uppercase of payment method data.
    const paymentType: any = Object.entries(selectedPayment).reduce(
      (obj: any, [key, value]) => {
        obj[key[0].toUpperCase() + key.substr(1)] = value;
        return obj;
      },
      {}
    );
    const paymentDataPayload = {
      sessionID,
      deliveryType: 'PostDelivery',
      paymentType,
    };
    const res = await ticketsService.setPaymentData(paymentDataPayload);
    if (res.documentElement.textContent === 'false') {
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      if (!isSessionExpired(error)) {
        scrollToTabs();
        setPaymentError(error);
        setPaymentSaving(false);
      }
    } else {
      // if it's existing order, then do checkout without creating new order.
      if (isExistingOrder) {
        if (!selectedPayment.isOnlinePayment) {
          setPaymentSuccessData({
            vorkasse: true,
          });
          setPaymentSuccess(true);
          return;
        }
        checkouts(orderToken);
      } else if (!isExistingOrder && orderNumber) {
        // if it's already saved order and went to payment page then hit back again to choose another payment method, then do checkout without creating new order.
        checkouts(orderToken);
      } else {
        saveOrder();
      }
    }
  };

  const continueExistingOrder = async (orderToken: string) => {
    const payload: any = {
      orderToken,
      sessionID,
    };
    await ticketsService.continueExistingOrder(payload);
  };

  const saveOrder = async () => {
    const res = await ticketsService.saveOrder({ sessionID });
    if (Number(res.documentElement.textContent) === -1) {
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      if (!isSessionExpired(error)) {
        scrollToTabs();
        setPaymentError(error || 'Speichern der Auftragsdaten fehlgeschlagen');
        setPaymentSaving(false);
        return;
      }
    } else {
      // order saved successfully
      const selectedPayment: Payment = paymentTypes.filter(
        (item: Payment) => item.name === activePaymentMethod.value
      )[0];
      setOrderNumber(Number(res.documentElement.textContent));
      if (!selectedPayment.isOnlinePayment) {
        setPaymentSuccessData({
          vorkasse: true,
        });
        setPaymentSuccess(true);
        return;
      }
      // PayUnity Checkouts
      const orderTokenRes = await ticketsService.getOrderToken({
        orderNumber: res.documentElement.textContent,
        sessionID,
      });
      setOrderToken(orderTokenRes.documentElement.textContent);
      checkouts(orderTokenRes.documentElement.textContent);
    }
  };

  const checkouts = async (orderToken: string) => {
    // It has to validate this regex /^[0-9]{1,12}(\.[0-9]{2})?$/
    const amountToSend = amount.toString().includes('.')
      ? amount.toString() + '0'
      : amount.toString() + '.00';
    const payload = {
      currency,
      orderToken,
      ipAddress: ipAddress.replaceAll('.', '-'),
      amount: amountToSend,
      paymentType: activePaymentMethod.paymentType,
      entityId:
        activePaymentMethod.value === 'SEPA-Online' ||
        activePaymentMethod.value === 'Bankeinzug'
          ? config.SEPA_entityId
          : config.entityId,
    };
    const res = await paymentService.checkouts(payload);
    if (res) {
      setPaymentSaving(false);
      setCheckoutID(res);
      setPaymentScript(res);
      scrollToTabs();
    }
  };

  const scrollToPaymentInfo = () => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('.payment-options-box').offset().top - 100,
      },
      1000
    );
  };

  const setActivePayment = (item: PaymentTypeData) => {
    if (
      paymentTypes?.length === 0 ||
      (item.value === 'APPLEPAY' && !isIosDevice()) ||
      !paymentTypesToDisplay.includes(item.value)
    )
      return;
    setActivePaymentMethod(item);
  };

  useEffect(() => {
    $.get(
      'https://api.ipify.org?format=jsonp',
      function (response: any) {
        setIpAddress(response.ip);
      },
      'jsonp'
    );
  }, []);

  useEffect(() => {
    if (paymentTypes?.length) {
      setPaymentTypesToDisplay(paymentTypes.map((item: any) => item.name));
    }
  }, [paymentTypes]);

  return (
    <div className="ticket-payment-tab">
      {!checkoutID && (
        <>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} md={6} className="ticket-payment-first-section">
              <Box component="section">
                <Box component="h2" mb={3} className="main-title-theme">
                  {t('zahlung')}
                </Box>
                {(paymentError || paymentErrorData) && (
                  <Box mb={3}>
                    <ErrorBox
                      type={t('Zahlung Fehlgeschlagen')}
                      text={
                        paymentError ? t(paymentError) : t(paymentErrorData)
                      }
                    />
                  </Box>
                )}
                <Box component="p" mb={1} className="main-text-theme">
                  {t(
                    'Bitte wählen Sie Ihre gewünschte Zahlungsart aus und klicken Sie nach der Auswahl auf Kostenpflichtig bestellen'
                  )}
                  :
                </Box>
                <Box component="p" mb={3} className="small-text-theme">
                  {t(
                    'Mehr Informationen zu den Zahlungsmöglichkeiten finden Sie'
                  )}{' '}
                  <span
                    className="small-link-theme"
                    onClick={scrollToPaymentInfo}
                  >
                    {t('weiter unten')}
                  </span>
                  .
                </Box>
                <Grid item container spacing={2}>
                  {paymentTypes?.length
                    ? PaymentTypesData.map((item: PaymentTypeData, index) => {
                        return (
                          <Grid item xs={12} key={index}>
                            <Box
                              onClick={() => setActivePayment(item)}
                              display={
                                !paymentTypesToDisplay.includes(item.value)
                                  ? 'none'
                                  : ''
                              }
                            >
                              <PaymentBox
                                name={t(item.name)}
                                image={item.image}
                                iconPrefix={item.iconPrefix}
                                iconName={item.iconName}
                                description={t(item.description)}
                                active={item.name === activePaymentMethod.name}
                                disabled={
                                  (item.value === 'APPLEPAY' &&
                                    !isIosDevice()) ||
                                  !paymentTypesToDisplay.includes(item.value)
                                }
                              />
                            </Box>
                          </Grid>
                        );
                      })
                    : null}
                  {Boolean(!activePaymentMethod?.name && submitPaymentForm) && (
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <ErrorBox
                          type={t('error')}
                          text={t('choose_payment_option')}
                        ></ErrorBox>
                      </Box>
                    </Grid>
                  )}
                  {Boolean(
                    paymentTypesLoaded && paymentTypes?.length === 0
                  ) && (
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <ErrorBox
                          type={t('error')}
                          text={t('no_payment_options')}
                        ></ErrorBox>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="ticket-payment-second-section">
              <Box component="section">
                <Box component="h2" mb={3} className="main-title-theme">
                  {t('Vorschau')}
                </Box>
                {selectedPrice?.priceCardWithoutCZ && (
                  <>
                    <TicketCardPreview selectedPayment={activePaymentMethod} />
                    <Grid item container spacing={3} alignItems="center">
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          left={'-10px'}
                          position="relative"
                        >
                          <Checkbox
                            checked={termsAccepted}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              setTermsAccepted(event.target.checked)
                            }
                            name="termsAccepted"
                            color="primary"
                            className={
                              !termsAccepted && submitPaymentForm
                                ? 'checkbox-required'
                                : ''
                            }
                          />
                          <Box ml={{ md: 1 }} className="small-text-theme">
                            {t('Ich akzeptiere die')}{' '}
                            <a
                              href="https://www.ooevv.at/de/agb.html"
                              rel="noreferrer"
                              target="_blank"
                            >
                              {t('AGBs')}
                            </a>{' '}
                            {t('der OÖ-Verkehrsverbund-Organisations GmbH Nfg')}
                            . &amp; CO KG
                          </Box>
                        </Box>
                        {Boolean(!termsAccepted && submitPaymentForm) && (
                          <Box mt={2}>
                            <ErrorBox
                              type={t('error')}
                              text={t('accept_terms_payment')}
                            ></ErrorBox>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4} justify="flex-end" alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column-reverse', md: 'row !important' }}
                alignItems="center"
                justifyContent="space-between"
                mt={2}
              >
                <Box mr={{ md: 2 }} mb={2}>
                  {isTabDisabled ? (
                    <Button
                      className="btn-theme btn-submit btn-small trim"
                      variant="outlined"
                      color="secondary"
                      id="05-20"
                      onClick={back}
                    >
                      {t('Daten oder Produkt ändern')}
                    </Button>
                  ) : (
                    <Button
                      onClick={back}
                      className="btn-text"
                      variant="text"
                      color="secondary"
                      id="05-10"
                    >
                      {t('Schritt zurück')}
                    </Button>
                  )}
                </Box>
                <Box
                  mb={2}
                  width={{ xs: '100%', md: '275px !important' }}
                  whiteSpace="nowrap"
                >
                  <Button
                    className="btn-theme btn-submit btn-small"
                    variant="contained"
                    color="primary"
                    onClick={setPaymentData}
                    disabled={paymentTypes?.length === 0}
                    id="05-30"
                  >
                    {isPaymentSaving ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      t('KOSTENPFLICHTIG BESTELLEN')
                    )}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {checkoutID && activePaymentMethod.brand && (
        <>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12}>
              <Box width="100%" minHeight="350px" position="relative">
                <Box mb={3}>
                  <Trans
                    i18nKey="payment_note"
                    values={{ payment: t(activePaymentMethod.name) }}
                  ></Trans>
                </Box>
                <Box mb={2} textAlign="center">
                  {'Gesamtpreis'}: <b>{formatCurrency(amount * 100)}</b>{' '}
                  {(activePaymentMethod.value === 'SEPA-Online' ||
                    activePaymentMethod.value === 'Bankeinzug') && (
                    <>
                      <Trans
                        i18nKey="sepa_installment_text"
                        values={{ SEPA_months: SEPA_months }}
                      ></Trans>{' '}
                      <b>{formatCurrency((amount * 100) / SEPA_months)}</b>
                    </>
                  )}
                </Box>
                <form
                  action={`${document.location.origin}/?orderNr=${orderNumber}&sprache=${lang}`}
                  className="paymentWidgets"
                  data-brands={activePaymentMethod.brand}
                ></form>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4} justify="flex-end" alignItems="center">
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
              >
                <Box mr={{ md: 2 }} mb={2}>
                  <Button
                    onClick={backToPayment}
                    className="btn-text"
                    variant="text"
                    color="secondary"
                    id="05-30"
                  >
                    {t('Schritt zurück')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
