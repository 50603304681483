import React, { useEffect, useState } from 'react';
import './ticket-selection-tab.component.scoped.scss';
import { Trans, useTranslation } from 'react-i18next';
import { TicketContext } from '../../store';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {
  DatePicker,
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import MomentUtils from '@date-io/moment';
import { LangService } from '../../services/lang/lang.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { dateFormat, xml2JsonConfig } from '../../statics/main';
import { TicketsService } from '../../services/tickets/tickets.service';
import { Ticket } from '../../types/Ticket';
import ErrorBox from '../error-box/error-box';
import { numRegex } from '../../statics/validators';
import { parseString } from 'xml2js';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { Certification } from '../../types/Certification';
import { CustomerImage } from '../../types/CustomerImage';
import { TicketStatus } from '../../statics/ticket-status';
import {
  handleSessionExpire,
  isSessionExpired,
  scrollToTabs,
} from '../../helpers';
import { TicketRenewResponse } from '../../types/TicketRenewResponse';
import { MapperService } from '../../services/mapper/mapper.service';
import SuccessBox from '../success-box/success-box';
import { TICKET } from '../../enums/ticket';

export const TicketSelectionTabComponent = () => {
  const [t] = useTranslation();
  const sessionStorageService = new SessionStorageService();
  const mapperService = new MapperService();
  const newTicket = sessionStorageService.getData('ticketForm') || {};
  const {
    setActiveTab,
    activeTab,
    setTicketValidFrom,
    setTicket,
    tickets,
    setOriginalUserImg,
    setCertification1Info,
    setCertification2Info,
    setCustomerImageInfo,
    setIsTicketExtended,
    isExtendTicket,
    sessionID,
  } = React.useContext(TicketContext);
  const ticketsService = new TicketsService();
  const langService: LangService = new LangService();
  const locale = langService.getCurrentLang();
  moment.locale(locale);
  const defaultStatus = { name: '', status: '', value: -1, date: '' };
  const [ticketStatus, setTicketStatus] = useState<{
    name: string;
    status: string;
    value: number;
    date: string;
  }>({ ...defaultStatus });
  const [ticketStatusError, setTicketStatusError] = useState<string>('');
  const [extendTicketError, setExtendTicketError] = useState<string>('');
  const [orderToken, setOrderToken] = useState<string>('');

  // TICKET SELECTION FORM
  const newTicketValidationSchema = yup.object({
    ticketId: yup.number().required(),
    ticketValidFromDate: yup
      .date()
      .required()
      .typeError(t('invalid_date_format')),
  });

  const newTicketForm = useFormik({
    initialValues: {
      ticketId: newTicket.ticketId || TICKET.KLIMA,
      ticketValidFromDate: newTicket.ticketValidFromDate || null,
    },
    validationSchema: newTicketValidationSchema,
    onSubmit: (values) => {
      if (!values.ticketValidFromDate) return;
      const selectedTicket: Ticket = tickets.filter(
        (ticket: Ticket) => ticket.ticketId === values.ticketId
      )[0];
      setTicketValidFrom(values.ticketValidFromDate);
      setTicket(selectedTicket);
      sessionStorageService.setData('ticketForm', values);
      // for ticket info after payment success redirect.
      sessionStorageService.setData('selectedTicket', selectedTicket);
      setActiveTab(activeTab + 1);
      scrollToTabs();
    },
  });

  // ONLINE ORDER STATE
  const orderStateValidationSchema = yup.object({
    identifier: yup.string().required().max(10, '').matches(numRegex),
    birthDate: yup
      .date()
      .min(moment('1900-01-01').format('YYYY-MM-DD'))
      .max(moment())
      .required()
      .typeError(t('invalid_date_format')),
  });

  const orderStateForm = useFormik({
    initialValues: {
      birthDate: null,
      identifier: '',
    },
    validationSchema: orderStateValidationSchema,
    onSubmit: (values) => {
      if (!orderStateForm.isValid) return;
      GetOnlineOrderState(values);
    },
  });

  // Extend Ticket Form
  const extendTicketValidationSchema = yup.object({
    customernr: yup.string().required().max(10, '').matches(numRegex),
    birthDate: yup
      .date()
      .min(moment('1900-01-01').format('YYYY-MM-DD'))
      .max(moment())
      .required()
      .typeError(t('invalid_date_format')),
  });

  const extendTicketForm = useFormik({
    initialValues: {
      birthDate: null,
      customernr: '',
    },
    validationSchema: extendTicketValidationSchema,
    onSubmit: (values) => {
      if (!extendTicketForm.isValid) return;
      getExtendTicketData(values);
    },
  });

  const handleIdentifierChange = (event: any) => {
    const identifier = event.target.value;
    (!identifier || numRegex.test(identifier)) &&
      orderStateForm.setFieldValue('identifier', identifier);
  };

  const handleCustomerNumberChange = (event: any) => {
    const customernr = event.target.value;
    (!customernr || numRegex.test(customernr)) &&
      extendTicketForm.setFieldValue('customernr', customernr);
  };

  // validation of Ticket Valid From Date
  const handleValidDateChange = (selectedValidDate: MaterialUiPickersDate) => {
    // if ticket is yearly ticket or klima ticket, we select first day on month.
    if (
      newTicketForm.values.ticketId === TICKET.YEAR ||
      newTicketForm.values.ticketId === TICKET.KLIMA
    ) {
      // check for valid date range
      if (isKlimaOrYearDateInvalid(selectedValidDate)) {
        return;
      }      
      if (selectedValidDate) {
        selectedValidDate = moment(selectedValidDate).utcOffset(0, true);
        selectedValidDate.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
      newTicketForm.setFieldValue(
        'ticketValidFromDate',
        selectedValidDate?.startOf('month')
      );
    } else {
      newTicketForm.setFieldValue('ticketValidFromDate', selectedValidDate);
    }
  };

  const clearTicketData = () => {
    const user = sessionStorageService.getData('user');
    const countryCode = sessionStorageService.getData('countryCode');
    sessionStorageService.clearAll();
    sessionStorageService.setData('session', sessionID);
    if (isExtendTicket) {
      if (user) sessionStorageService.setData('user', user);
      if (countryCode)
        sessionStorageService.setData('countryCode', countryCode);
    }
    setOriginalUserImg('');
    setCertification1Info(new Certification(1));
    setCertification2Info(new Certification(2));
    setCustomerImageInfo(new CustomerImage());
  };

  const handleTicketTypeChange = (value: string) => {
    newTicketForm.setFieldValue('ticketId', Number(value));
    newTicketForm.setFieldValue('ticketValidFromDate', null);
    // Clear data on ticket change
    clearTicketData();
  };

  // validation on Semester Date
  // From 01/02/YYYY to 31/03/YYYY
  // From 01/09/YYYY to 31/10/YYYY
  const disableSemesterDate = (selectedValidDate: MaterialUiPickersDate) => {
    // reset time on selected date to compare dates correctly.
    if (selectedValidDate) {
      selectedValidDate = moment(selectedValidDate).utcOffset(0, true);
      selectedValidDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    if (
      selectedValidDate &&
      (selectedValidDate.diff(
        moment(`${moment().year()}-02-01`, 'YYYY-MM-DD').utcOffset(0, true),
        'days'
      ) < 0 ||
        (selectedValidDate.diff(
          moment(`${moment().year()}-03-31`, 'YYYY-MM-DD').utcOffset(0, true),
          'days'
        ) > 0 &&
          selectedValidDate.diff(
            moment(`${moment().year()}-09-01`, 'YYYY-MM-DD').utcOffset(0, true),
            'days'
          ) < 0) ||
        selectedValidDate.diff(
          moment(`${moment().year()}-10-31`, 'YYYY-MM-DD').utcOffset(0, true),
          'days'
        ) > 0)
    ) {
      return true;
    }
    return false;
  };

  const calendarOpenTo = (): DatePickerView | undefined => {
    if (
      newTicketForm.values.ticketId === TICKET.YEAR ||
      newTicketForm.values.ticketId === TICKET.KLIMA
    ) {
      // Month is a zero based index from 0-11
      // If month is Nov or Dec , then it will be 2 years at calendar (open to YEAR first)
      if (moment().month() < 10) {
        return 'month';
      }
      return 'year';
    }
    return undefined;
  };

  const isKlimaOrYearDateInvalid = (
    selectedValidDate: MaterialUiPickersDate
  ): boolean => {
    if (
      selectedValidDate &&
      (moment(selectedValidDate)
        .utcOffset(0, true)
        .diff(moment().startOf('month'), 'month') < 0 ||
        moment(selectedValidDate)
          .utcOffset(0, true)
          .diff(moment().startOf('month'), 'month') > 2)
    ) {
      return true;
    }
    return false;
  };

  const getExtendTicketData = (values: any) => {
    clearTicketData();
    sessionStorageService.removeData('countryCode');
    setExtendTicketError('');
    const payload: any = {
      customernr: Number(values.customernr),
      birthDate: values.birthDate?.utcOffset(0, true).toDate(),
      sessionID,
    };
    ticketsService
      .newOrderWithStoredData(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) => {
          parseTicketData(err, res);
        });
      })
      .fail(function (jqXHR: any, textStatus: string, errorThrown: Error) {
        setExtendTicketError('Fehler beim Abfragen eines neuen Auftrags!');
      });
  };

  const parseTicketData = async (err: Error | null, res: any) => {
    const ticketRenewResponse: TicketRenewResponse =
      res['soap:Envelope']['soap:Body'];
    if (!ticketRenewResponse['types:OrderData']) {
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      if (!isSessionExpired(error))
        setExtendTicketError('Fehler beim Abfragen eines neuen Auftrags!');
    } else {
      setIsTicketExtended(true);
      setCustomerData(ticketRenewResponse);
    }
  };

  const setCustomerData = (res: TicketRenewResponse) => {
    // Set Tab 2 Data (User Data, University)
    const customerData = Array.isArray(res['types:CustomerData'])
      ? res['types:CustomerData'][0]
      : res['types:CustomerData'];
    const university = res['types:University'];
    const user = mapperService.mapUserData(customerData, university);
    sessionStorageService.setData('user', user);
    // Set Tab 3 Data (Images, Files)
    const customerImage =
      'data:image/png;base64,' + res['types:UploadFile'].imageBase64;
    const fileExtension = res['types:UploadFile'].fileExtension;
    sessionStorageService.setData('croppedImage', customerImage);
    sessionStorageService.setData('fileExtension', fileExtension);
  };

  const getTicketStatusError = async () => {
    const error: string = (await ticketsService.getErrorText({ sessionID }))
      .documentElement.textContent;
    handleSessionExpire(error);
    if (!isSessionExpired(error)) setTicketStatusError(error);
  };

  const GetOnlineOrderState = (values: {
    identifier: string;
    birthDate: Moment | null;
  }) => {
    clearTicketData();
    setTicketStatusError('');
    const payload: any = {
      identifier: Number(values.identifier),
      birthDate: values.birthDate?.utcOffset(0, true).toDate(),
      sessionID,
    };
    ticketsService
      .getOnlineOrderState(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) => {
          if (res) {
            const {state, date} = res['soap:Envelope']['soap:Body']['types:OrderState'];
            const selectedStatus = TicketStatus.filter(
              (status) => state === status.value
            )[0];
            setTicketStatus({
              name: selectedStatus ? selectedStatus.name : '',
              status: selectedStatus ? selectedStatus.status : '',
              value: state,
              date: moment(date, "YYYY-MM-DD THH:mm:ss").format("DD.MM.YYYY HH:mm")
            });
            if (state === 0) {
              getTicketStatusError();
            }
            if (state === 7 || state === 8) {
              getOrderToken(Number(values.identifier));
            }
          }
        });
      })
      .fail(function (jqXHR: any, textStatus: string, errorThrown: Error) {
        setTicketStatusError('Fehler beim Abfragen des Status der Bestellung!');
      });
  };

  const getOrderToken = async (orderNumber: number) => {
    const payload: any = {
      orderNumber,
      sessionID,
    };
    const res = await ticketsService.getOrderToken(payload);
    if (res) {
      setOrderToken(res.documentElement.textContent);
    }
  };

  const logout = () => {
    orderStateForm.resetForm();
    setTicketStatus({ ...defaultStatus });
    setOrderToken('');
    sessionStorageService.removeData('orderNumber');
    sessionStorageService.removeData('birthDate');
  };

  useEffect(() => {
    const orderNumber = sessionStorageService.getData('orderNumber');
    const birthDate = sessionStorageService.getData('birthDate');
    if (orderNumber && birthDate && sessionID) {
      GetOnlineOrderState({
        identifier: orderNumber,
        birthDate: moment(birthDate),
      });
      orderStateForm.setFieldValue('identifier', orderNumber);
      orderStateForm.setFieldValue('birthDate', moment(birthDate));
      sessionStorageService.removeData('orderNumber');
      sessionStorageService.removeData('birthDate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionID]);

  return (
    <div className="ticket-selection-tab">
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={6} className="ticket-selection-first-section">
          <Box component="section" height={{ md: '100%' }} position="relative">
            <Box component="h2" mb={3} className="main-title-theme">
              {t('Neues Ticket kaufen')}
            </Box>
            <Box component="p" mb={3} className="main-text-theme">
              {t('Bitte wählen Sie das gewünschte Ticket')}.
            </Box>
            <form
              onSubmit={newTicketForm.handleSubmit}
              autoComplete="off"
              id="01-10"
            >
              <Box mb={1}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="ticket type"
                    name="ticketId"
                    value={newTicketForm.values.ticketId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleTicketTypeChange(event.target.value)
                    }
                  >
                    {tickets.map((ticket: Ticket, index: number) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={ticket.ticketId}
                          control={<Radio color="primary" />}
                          label={t(ticket.ticketname)}
                          name="ticketId"
                          color="primary"
                          classes={{ label: 'ticket-select-label' }}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Box>
              {tickets.length > 0 && (
                <>
                  <Box
                    width={{ md: '75%', lg: '50%' }}
                    className="ticket-valid-date"
                  >
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                      locale={locale}
                    >
                      <DatePicker
                        variant="inline"
                        inputVariant="standard"
                        format={dateFormat}
                        views={
                          newTicketForm.values.ticketId !== TICKET.SEMESTER
                            ? ['year', 'month']
                            : undefined
                        }
                        minDate={
                          newTicketForm.values.ticketId === TICKET.YEAR
                            ? moment().startOf('month')
                            : newTicketForm.values.ticketId === TICKET.SEMESTER
                            ? moment().startOf('year')
                            : newTicketForm.values.ticketId === TICKET.KLIMA
                            ? moment().startOf('month')
                            : moment()
                        }
                        maxDate={
                          newTicketForm.values.ticketId === TICKET.YEAR
                            ? moment().utcOffset(0, true).add(2, 'month').endOf('month')
                            : newTicketForm.values.ticketId === TICKET.SEMESTER
                            ? moment().utcOffset(0, true).endOf('year')
                            : newTicketForm.values.ticketId === TICKET.KLIMA
                            ? moment().utcOffset(0, true).add(2, 'month').endOf('month')
                            : undefined
                        }
                        openTo={calendarOpenTo()}
                        shouldDisableDate={
                          newTicketForm.values.ticketId === TICKET.SEMESTER
                            ? disableSemesterDate
                            : undefined
                        }
                        autoOk
                        name="ticketValidFromDate"
                        value={newTicketForm.values.ticketValidFromDate}
                        onChange={(value) => handleValidDateChange(value)}
                        className="w-100"
                        label={t('ticket gültig ab') + ' *'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="toggle-password"
                                aria-label="toggle password visibility"
                              >
                                <EventOutlinedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        invalidDateMessage={t('invalid_date_format')}
                        maxDateMessage=""
                        minDateMessage=""
                        error={Boolean(
                          (newTicketForm.errors.ticketValidFromDate &&
                            newTicketForm.values.ticketValidFromDate) ||
                            (!newTicketForm.values.ticketValidFromDate &&
                              newTicketForm.submitCount)
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  {Boolean(
                    !newTicketForm.values.ticketValidFromDate &&
                      newTicketForm.submitCount
                  ) && (
                    <Box mt={3} width={{ md: '75%', lg: '50%' }}>
                      <ErrorBox
                        type={t('error')}
                        text={t('complete_required_fields')}
                      ></ErrorBox>
                    </Box>
                  )}
                  <Box
                    mt={{
                      xs: 3,
                      md: '164px !important',
                      lg: '116px !important',
                      xl: '64px !important',
                    }}
                    width={{ md: '75%', lg: '50%' }}
                  >
                    <Button
                      className="btn-theme btn-submit"
                      variant="contained"
                      color="primary"
                      type="submit"
                      id="01-20"
                    >
                      {t('Weiter')}
                    </Button>
                  </Box>
                </>
              )}
            </form>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Divider variant="middle" />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} className="ticket-selection-second-section">
          <Box component="section" height={{ md: '100%' }} position="relative">
            <Box
              component="h2"
              mb={3}
              className="main-title-theme"
              minHeight={{ md: 58, xl: 'auto' }}
            >
              {t('Übernehmen')}
            </Box>
            <Box
              component="p"
              mb={2}
              className="main-text-theme"
              minHeight={{ md: 168, lg: 120, xl: 96 }}
            >
              {t(
                'Sie haben bereits ein Ticket bestellt und möchten Ihre Daten für eine neue Bestellung übernehmen?'
              )}
            </Box>
            {!isExtendTicket && (
              <form
                onSubmit={extendTicketForm.handleSubmit}
                autoComplete="off"
                id="01-30"
              >
                <Box mb={1.5}>
                  <TextField
                    className="w-100"
                    label={t('Kundennummer')}
                    variant="standard"
                    name="customernr"
                    value={extendTicketForm.values.customernr}
                    onChange={handleCustomerNumberChange}
                    inputProps={{ maxLength: 10 }}
                    error={Boolean(
                      extendTicketForm.errors.customernr &&
                        extendTicketForm.submitCount
                    )}
                  />
                </Box>
                <Box mb={4}>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                    locale={locale}
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      inputVariant="standard"
                      format="DD.MM.YYYY"
                      autoOk
                      name="birthDate"
                      value={extendTicketForm.values.birthDate}
                      onChange={(value) =>
                        extendTicketForm.setFieldValue('birthDate', value)
                      }
                      className="w-100"
                      label={t('Geburtsdatum')}
                      keyboardIcon={<EventOutlinedIcon />}
                      invalidDateMessage={t('invalid_date_format')}
                      maxDateMessage={t('invalid_birth_date')}
                      minDateMessage={t('invalid_birth_date')}
                      disableFuture
                      error={Boolean(
                        (extendTicketForm.errors.birthDate &&
                          extendTicketForm.values.birthDate) ||
                          (!extendTicketForm.values.birthDate &&
                            extendTicketForm.submitCount)
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                {Boolean(
                  !extendTicketForm.isValid && extendTicketForm.submitCount
                ) && (
                  <Box mb={4}>
                    <ErrorBox
                      type={t('error')}
                      text={t('complete_required_fields')}
                    ></ErrorBox>
                  </Box>
                )}
                {extendTicketError && (
                  <Box mb={4}>
                    <ErrorBox type={t('error')} text={t(extendTicketError)} />
                  </Box>
                )}
                <Box>
                  <Button
                    className="btn-theme btn-submit trim"
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    id="01-40"
                  >
                    {/* <FontAwesomeIcon icon="ticket-alt" /> */}
                    {t('Übernehmen')}
                  </Button>
                </Box>
              </form>
            )}
            {isExtendTicket && (
              <Box>
                <SuccessBox
                  text={t('Ihre Daten wurden übernommen')}
                ></SuccessBox>
              </Box>
            )}
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Divider variant="middle" />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} className="ticket-selection-third-section">
          <Box component="section" height={{ md: '100%' }} position="relative">
            <Box
              component="h2"
              mb={3}
              className="main-title-theme"
              minHeight={{ md: 58, xl: 'auto' }}
            >
              {t('Statusabfrage')}
            </Box>
            {ticketStatus.value <= 0 && (
              <>
                <Box
                  component="p"
                  mb={2}
                  className="main-text-theme"
                  minHeight={{ md: 168, lg: 120, xl: 96 }}
                >
                  {t(
                    'Sie haben Ihr Ticket schon bestellt und möchten den Bestellstatus abfragen?'
                  )}
                </Box>
                <form
                  onSubmit={orderStateForm.handleSubmit}
                  autoComplete="off"
                  id="01-50"
                >
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('Bestellnummer')}
                      variant="standard"
                      name="identifier"
                      value={orderStateForm.values.identifier}
                      onChange={handleIdentifierChange}
                      inputProps={{ maxLength: 10 }}
                      error={Boolean(
                        orderStateForm.errors.identifier &&
                          orderStateForm.submitCount
                      )}
                    />
                  </Box>
                  <Box mb={4}>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                      locale={locale}
                    >
                      <KeyboardDatePicker
                        variant="inline"
                        inputVariant="standard"
                        format="DD.MM.YYYY"
                        autoOk
                        name="birthDate"
                        value={orderStateForm.values.birthDate}
                        onChange={(value) =>
                          orderStateForm.setFieldValue('birthDate', value)
                        }
                        className="w-100"
                        label={t('Geburtsdatum')}
                        keyboardIcon={<EventOutlinedIcon />}
                        invalidDateMessage={t('invalid_date_format')}
                        maxDateMessage={t('invalid_birth_date')}
                        minDateMessage={t('invalid_birth_date')}
                        disableFuture
                        error={Boolean(
                          (orderStateForm.errors.birthDate &&
                            orderStateForm.values.birthDate) ||
                            (!orderStateForm.values.birthDate &&
                              orderStateForm.submitCount)
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  {Boolean(
                    !orderStateForm.isValid && orderStateForm.submitCount
                  ) && (
                    <Box mb={4}>
                      <ErrorBox
                        type={t('error')}
                        text={t('complete_required_fields')}
                      ></ErrorBox>
                    </Box>
                  )}
                  {ticketStatusError && (
                    <Box mb={4}>
                      <ErrorBox type={t('error')} text={t(ticketStatusError)} />
                    </Box>
                  )}
                  <Box>
                    <Button
                      className="btn-theme btn-submit"
                      variant="outlined"
                      color="secondary"
                      type="submit"
                      id="01-60"
                    >
                      {t('PRÜFEN')}
                    </Button>
                  </Box>
                </form>
              </>
            )}
            {ticketStatus.value > 0 && (
              <>
                <Box
                  component="p"
                  mb={2}
                  className="main-text-theme"
                  minHeight={{ md: 168, lg: 120, xl: 96 }}
                >
                  <Trans
                    i18nKey="Ihr Ticket mit der Nummer hat folgenden Status"
                    values={{ identifier: orderStateForm.values.identifier }}
                  ></Trans>
                  :{' '}
                  {(ticketStatus.value === 7 || ticketStatus.value === 8) && orderToken ? (
                    // PAY AGAIN FOR STATUS 7 & 8 (WAITING FOR PAYMENT, PAYMENT CANCELLED)
                    <a
                      href={`/?sprache=${locale}&orderToken=${orderToken}`}
                      className="main-link-theme"
                    >
                      {t(ticketStatus.status)}
                    </a>
                  ) : (
                    <b>{t(ticketStatus.status)} - {t(ticketStatus.name)}</b>
                  )}
                  {ticketStatus.date &&
                    <Box className="small-text-theme" marginTop={0.5}>{t('Letzte Änderung')}: {ticketStatus.date}</Box>
                  }
                </Box>
                {/* <Box mb={3} position="relative" left={"8px"}>
                  <ul className="ticket-selection-status">
                    {TicketStatus.map((status) => {
                      return (
                        <li
                          key={status.value}
                          className={
                            "ticket-selection-status-item " +
                            (ticketStatus.value === status.value
                              ? "active"
                              : "")
                          }
                        >
                          <span className="ticket-selection-status-name">
                            {t(status.name)}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </Box> */}
                <Box marginTop={{ md: '156px' }}>
                  <Button
                    onClick={logout}
                    className="btn-theme btn-submit"
                    variant="outlined"
                    color="secondary"
                    id="01-70"
                  >
                    <FontAwesomeIcon icon="ticket-alt" />
                    {t('AUSLOGGEN')}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
