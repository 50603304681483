import React, { useEffect, useState } from "react";
import "./track-stages.scoped.scss";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";
import { TicketsService } from "../../services/tickets/tickets.service";
import { xml2JsonConfig } from "../../statics/main";
import { parseString } from "xml2js";
import { Stage } from "../../types/Stage";
import { Autocomplete } from "@material-ui/lab";
import { SessionStorageService } from "../../services/session-storage/session-storage";

interface TrackStagesProps {
  label: string;
  name: string;
  required?: boolean;
  stage: Stage;
  disabled?: boolean;
  error?: boolean;
  onStageChange: (stage: Stage | null) => void;
}

export const TrackStages = (props: TrackStagesProps) => {
  const [t] = useTranslation();
  const { label, name, required, stage, onStageChange, disabled, error } = props;
  const ticketsService = new TicketsService();
  const sessionStorageService = new SessionStorageService();
  const sessionID = sessionStorageService.getData("session");
  const [stages, setStages] = useState<Stage[]>([]);
  const [value, setValue] = React.useState<Stage | null>(
    stage.stageName ? stage : null
  );
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = useState(false);

  const getStages = async (filter = "") => {
    setLoading(true);
    ticketsService
      .getStages({ sessionID, filter })
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parseStages(err, res)
        );
      })
      .fail(function (jqXHR: any, textStatus: string, errorThrown: Error) {
        setLoading(false);
      });
  };

  const parseStages = (err: Error | null, res: any) => {
    if (res) {
      const stages: any =
        res["soap:Envelope"]["soap:Body"]["types:Stage"] || [];
      Array.isArray(stages) ? setStages(stages) : setStages([stages]);
      setLoading(false);
    }
  };

  const handleInputChange = (query: string) => {
    setInputValue(query);
  };

  const handleOnChange = (newValue: Stage | null) => {
    setValue(newValue);
    onStageChange(newValue);
  };

  useEffect(() => {
    if (inputValue.length > 2) {
      getStages(inputValue);
    } else {
      setStages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        id={name}
        className="w-100"
        autoHighlight
        autoComplete
        options={stages}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);
        }}
        getOptionLabel={(option: Stage) => option.stageName}
        getOptionSelected={(option: Stage, value: Stage) =>
          option.stageName === value.stageName
        }
        value={value}
        onChange={(event: any, newValue: Stage | null) => {
          handleOnChange(newValue);
        }}
        loading={loading}
        style={{ width: "100%" }}
        noOptionsText={t("no_options")}
        loadingText={t("loading_options")}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            required={required}
            disabled={disabled}
            error={Boolean(error)}
          />
        )}
      />
      <Box
        textAlign="right"
        className="small-text-theme"
        color="rgba(0, 0, 0, 0.54)"
      >
        min. 3 {t("Zeichen")}
      </Box>
    </>
  );
};
