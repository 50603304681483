import React, { useEffect, useState } from 'react';
import './ticket-track-tab.scoped.scss';
import { Trans, useTranslation } from 'react-i18next';
import { TicketContext } from '../../store';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import RouteBox from '../route-box/route-box';
import ErrorBox from '../error-box/error-box';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { TicketsService } from '../../services/tickets/tickets.service';
import { TrackStages } from '../track-stages/track-stages';
import { Stage } from '../../types/Stage';
import { parseString } from 'xml2js';
import { xml2JsonConfig } from '../../statics/main';
import { PathDetails } from '../../types/PathDetails';
import { Route } from '../../types/Route';
import { PriceInfo } from '../../types/PriceInfo';
import { Certification } from '../../types/Certification';
import { RouteStages } from '../../types/RouteStages';
import { CoreZone } from '../../types/CoreZone';
import {
  formatCurrency,
  handleSessionExpire,
  isSessionExpired,
  scrollToTabs,
} from '../../helpers';
import { LangService } from '../../services/lang/lang.service';
import { TICKET } from '../../enums/ticket';

export const TicketTrackTab = () => {
  const [t] = useTranslation();
  const ticketsService = new TicketsService();
  const sessionStorageService = new SessionStorageService();
  const sessionID = sessionStorageService.getData('session');
  const {
    setActiveTab,
    activeTab,
    validFrom,
    ticket,
    certification1Info,
    certification2Info,
    setSelectedRoute,
    selectedRoute,
    setSelectedPrice,
    selectedPrice,
    setSelectedCoreZone,
    selectedCoreZone,
  } = React.useContext(TicketContext);

  const langService: LangService = new LangService();
  const lang = langService.getCurrentLang();
  const coreZonesLink = `https://www.ooevv.at/?seite=zonenplan&sprache=${lang.toUpperCase()}`;

  const routeStagesStored: RouteStages =
    sessionStorageService.getData('routeStages') || new RouteStages();

  const universityUploadConfirmation = sessionStorageService.getData(
    'universityUploadConfirmation'
  );

  const [routeStages, setRouteStages] = useState<RouteStages>(
    routeStagesStored
  );

  const [possiblePathes, setPossiblePathes] = useState<PathDetails>({} as any);

  // used for loading on submit buttom
  const [possiblePathesLoading, setPossiblePathesLoading] = useState(false);

  // used for show a warning msg when no routes found.
  const [possiblePathesLoaded, setPossiblePathesLoaded] = useState(false);
  
  // used for show a warning msg when no routes found.
  const [possiblePathesError, setPossiblePathesError] = useState("");

  // used for show required error msg on stages
  const [submitStageForm, setSubmitStageForm] = useState(false);

  // Used for disable submit buttom (if there are stored data in the session).
  const [
    possiblePathesLoadingSilent,
    setPossiblePathesLoadingSilent,
  ] = useState(false);

  const [ticketPrices, setTicketPrices] = useState<PriceInfo[]>([]);
  const [routeError, setRouteError] = useState('');
  const [saving, setSaving] = useState(false);

  // used for show hint that klima ticket is cheaper
  const [
    isPriceHigherThanKlimaPrice,
    setIsPriceHigherThanKlimaPrice,
  ] = useState(false);

  const onStageChange = (stageType: string, stage: Stage | null) => {
    const routeStagesCopy: any = { ...routeStages };
    routeStagesCopy[stageType] = stage ? stage : new Stage();
    setRouteStages({ ...routeStagesCopy });
    sessionStorageService.setData('routeStages', { ...routeStagesCopy });
  };

  const getPossiblePathes = async (reset: boolean) => {
    setPossiblePathesLoading(true);
    const payload: any = {
      validFrom: moment(validFrom).utcOffset(0, true).toDate(),
      sessionID,
      fromStageNr: routeStages.fromStage.stageNr,
      toStageNr: routeStages.toStage.stageNr,
      // pass 0 if there is no Via
      viaStage: routeStages.viaStage.stageNr,
    };
    ticketsService
      .getPossiblePathes(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parsePossiblePathes(err, res, reset)
        );
      }).fail(function (jqXHR: any, textStatus: string, errorThrown: Error) {
        setPossiblePathesLoadingSilent(false);
        setPossiblePathesLoading(false);
        setPossiblePathesError("possible_pathes_error");
      });
  };

  const parsePossiblePathes = (err: Error | null, res: any, reset: boolean) => {
    if (res) {
      const priceInfo: PriceInfo[] =
        res['soap:Envelope']['soap:Body']['types:PriceInfo'] || [];
      const route = res['soap:Envelope']['soap:Body']['types:Route'] || [];
      const routeDetails =
        res['soap:Envelope']['soap:Body']['types:RouteDetails'] || [];
      const pathDetails = {
        priceInfo,
        routeDetails: Array.isArray(routeDetails)
          ? routeDetails
          : [routeDetails],
        route: Array.isArray(route) ? route : [route],
      };
      const prices: PriceInfo[] = getTicketPrices(priceInfo);
      const isPriceHigh = prices && prices.length &&
        ((ticket.ticketname.includes("Jahreskarte") &&
          prices.every(
            (price: PriceInfo) => price.priceCardWithoutCZ >= 365 * 100
          )) ||
        (ticket.ticketname.includes("Semesterkarte") &&
          prices.every(
            (price: PriceInfo) => price.priceCardWithoutCZ >= 274 * 100
          )));
      setIsPriceHigherThanKlimaPrice(isPriceHigh);
      prices && !isPriceHigh
        ? setPossiblePathes(pathDetails)
        : setPossiblePathes({} as any);
      setPossiblePathesLoading(false);
      setPossiblePathesLoadingSilent(false);
      setPossiblePathesLoaded(true);
      if (reset) {
        resetRoute();
      }
    } else {
      setPossiblePathesError("possible_pathes_error");
    }
  };

  const getTicketPrices = (priceInfo: PriceInfo[]): PriceInfo[] => {
    const prices: PriceInfo[] = priceInfo.filter(
      (price: PriceInfo) => price.ticketCode === ticket.ticketCode
    );
    setTicketPrices(prices);
    return prices;
  };

  const confirmRouteStages = (reset = false) => {
    setSubmitStageForm(true);
    setPossiblePathesLoaded(false);
    setPossiblePathesError('');
    if (
      possiblePathesLoading ||
      !routeStages.fromStage.stageName ||
      !routeStages.toStage.stageName ||
      saving
    ) {
      return;
    }
    getPossiblePathes(reset);
  };

  const resetRoute = () => {
    setRouteError('');
    setPossiblePathesError('');
    setSelectedRoute({} as Route);
    setSelectedPrice({} as PriceInfo);
    resetCoreZone();
  };

  const onRouteChange = (route: Route, index: number) => {
    if (!saving && route.routeId !== selectedRoute.routeId) {
      setRouteError('');
      setSelectedRoute(route);
      setSelectedPrice(ticketPrices[index]);
      resetCoreZone();
    }
  };

  const back = () => {
    setActiveTab(activeTab - 1);
    scrollToTabs();
  };

  const next = () => {
    setSaving(false);
    setActiveTab(activeTab + 1);
    scrollToTabs();
  };

  const uploadCertification = async (
    certificate: Certification,
    id: number
  ) => {
    console.log(`Uploading Certificate ${id}...`);
    const payload = {
      sessionID,
      certificationContent: certificate.certificationContent.replace(
        /^[^,]*,/,
        ''
      ),
      extension: certificate.extension.toUpperCase(),
      id,
    };
    const res = await ticketsService.uploadCertification(payload);
    if (res.documentElement.textContent === 'false') {
      setSaving(false);
      console.log(`Upload Certificate ${id} Failed`);
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      console.log(error);
    } else {
      console.log(`Upload Certificate ${id} Complete`);
    }
    return res;
  };

  const handleUploadCertifications = async () => {
    if (ticket.isSemesterWorkflow && certification1Info.certificationContent) {
      // const id = ticket.cert1Id;
      const uploadCertification1Res = await uploadCertification(
        certification1Info,
        1
      );
      if (
        uploadCertification1Res.documentElement.textContent === 'true' &&
        universityUploadConfirmation &&
        certification2Info.certificationContent
      ) {
        // const id = ticket.cert2Id;
        const uploadCertification2Res = await uploadCertification(
          certification2Info,
          2
        );
        if (uploadCertification2Res.documentElement.textContent === 'true') {
          next();
        }
      }
    } else {
      next();
    }
  };

  const setCardData = async (cz: CoreZone) => {
    setRouteError('');
    const payload = {
      sessionID,
      routeId: selectedRoute.routeId,
      ticketId: ticket.ticketId,
      priceInfoId: selectedPrice.priceInfoId,
      czFrom: cz.czFrom === 'ja' ? true : false,
      czTo: cz.czTo === 'ja' ? true : false,
    };
    setSaving(true);
    const res = await ticketsService.setCardData(payload);
    if (res.documentElement.textContent === 'false') {
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      if (!isSessionExpired(error)) {
        setRouteError(error);
        setSaving(false);
      }
    } else {
      handleUploadCertifications();
    }
  };

  const validationSchema = yup.object({
    czFrom: yup.string(),
    czTo: yup.string(),
  });

  const ticketRouteForm = useFormik({
    initialValues: {
      czFrom: selectedCoreZone.czFrom,
      czTo: selectedCoreZone.czTo,
    },
    validationSchema,
    onSubmit: (values) => {
      if (saving) return;
      setCardData(values);
    },
  });

  const resetCoreZone = () => {
    ticketRouteForm.setFieldValue('czFrom', '');
    ticketRouteForm.setFieldValue('czTo', '');
    setSelectedCoreZone(new CoreZone());
  };

  const selectKlimaticket = () => {
    const ticketForm = {
      ticketId: TICKET.KLIMA,
      ticketValidFromDate: null,
    };
    sessionStorageService.setData("ticketForm", ticketForm);
    setActiveTab(0);
    scrollToTabs();
  };

  useEffect(() => {
    if (
      routeStagesStored.toStage.stageName &&
      routeStagesStored.fromStage.stageName
    ) {
      setPossiblePathesLoadingSilent(true);
      confirmRouteStages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ticket-track-tab">
      <form onSubmit={ticketRouteForm.handleSubmit} autoComplete="off">
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={4} className="ticket-track-first-section">
            <Box component="section">
              <Box component="h2" mb={3} className="main-title-theme">
                1. {t('Streckendaten')}
              </Box>
              <Box component="p" mb={3} className="main-text-theme">
                {t('Geben Sie ihre Einstiegs- und Ausstiegshaltestelle ein')}.
              </Box>
              <Box mb={1.5} position="relative">
                <TrackStages
                  label={t('Einstieg')}
                  name="einstieg"
                  stage={routeStages.fromStage}
                  required={true}
                  onStageChange={(stage: Stage | null) =>
                    onStageChange('fromStage', stage)
                  }
                  disabled={saving}
                  error={!routeStages.fromStage.stageName && submitStageForm}
                />
              </Box>
              <Box mb={1.5} position="relative">
                <TrackStages
                  label={t('Ausstieg')}
                  name="ausstieg"
                  stage={routeStages.toStage}
                  required={true}
                  onStageChange={(stage: Stage | null) =>
                    onStageChange('toStage', stage)
                  }
                  disabled={saving}
                  error={!routeStages.toStage.stageName && submitStageForm}
                />
              </Box>
              <Box mb={3} position="relative">
                <TrackStages
                  label="Via"
                  name="via"
                  required={false}
                  stage={routeStages.viaStage}
                  onStageChange={(stage: Stage | null) =>
                    onStageChange('viaStage', stage)
                  }
                  disabled={saving}
                />
              </Box>
              {Boolean(
                (!routeStages.fromStage.stageName ||
                  !routeStages.toStage.stageName) &&
                  submitStageForm
              ) && (
                <Box mb={3}>
                  <ErrorBox
                    type={t('error')}
                    text={t('complete_required_fields')}
                  ></ErrorBox>
                </Box>
              )}
              <Box>
                <Button
                  className="btn-theme btn-submit"
                  variant="contained"
                  color="primary"
                  onClick={confirmRouteStages.bind(null, true)}
                  id="04-10"
                >
                  {possiblePathesLoading && !possiblePathesLoadingSilent ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    t('bestätigen')
                  )}
                </Button>
              </Box>
            </Box>
            <Box display={{ xs: 'block', md: 'none' }}>
              <Divider variant="middle" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="ticket-track-second-section">
            <Box component="section">
              <Box
                component="h2"
                mb={3}
                className={
                  'main-title-theme ' + (!possiblePathes.route ? 'dimmed' : '')
                }
              >
                2. {t('Routenauswahl')}
              </Box>
              {isPriceHigherThanKlimaPrice ? (
                <Box mb={3} whiteSpace="pre-line">
                  {ticket.ticketname.includes("Jahreskarte") && (
                    <ErrorBox
                      text={
                        <Trans i18nKey="klimaticket_is_cheaper_classic">
                          Für diese Fahrtstrecke ist das <strong>KlimaTicket OÖ Regional | Classic</strong> um € 365 das günstigste Ticket.<br/> Klicken Sie <span className="small-link-theme" onClick={selectKlimaticket}>HIER</span> um den Bestellvorgang für das KlimaTicket OÖ zu starten.
                        </Trans>
                      }
                    ></ErrorBox>
                  )}
                  {ticket.ticketname.includes("Semesterkarte") && (
                    <ErrorBox
                      text={
                        <Trans i18nKey="klimaticket_is_cheaper_junior">
                          Für diese Fahrtstrecke ist das <strong>KlimaTicket OÖ Regional | Junior</strong> um € 274 das günstigste Ticket.<br/> Klicken Sie <span className="small-link-theme" onClick={selectKlimaticket}>HIER</span> um den Bestellvorgang für das KlimaTicket OÖ zu starten.
                        </Trans>
                      }
                    ></ErrorBox>
                  )}
                </Box>
              ) : possiblePathes.route && possiblePathes.route.length ? (
                <Box component="p" mb={3} className="main-text-theme">
                  {t('Bitte geben Sie ihre gewünschte Fahrstrecke an')}
                </Box>
              ) : possiblePathesError ? (
                <Box mb={3}>
                  <ErrorBox type={t("error")} text={t(possiblePathesError)} />
                </Box>
              ) : possiblePathesLoaded ? (
                <Box mb={3}>
                  <ErrorBox text={t('no_routes_found')} />
                </Box>
              ) : (
                <Box component="p" mb={3} className="main-text-theme dimmed">
                  {t(
                    'Bitte geben Sie zuerst Ihre Streckendaten an, bevor Sie die gewünschte Fahrstrecke auswählen'
                  )}
                </Box>
              )}
              {possiblePathes.route && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {possiblePathes.route.map((route: Route, index: number) => {
                    return (
                      <Box
                        key={index}
                        mb={2}
                        width={'48%'}
                        onClick={() => onRouteChange(route, index)}
                      >
                        <RouteBox
                          route={route}
                          price={
                            ticketPrices.length > index
                              ? ticketPrices[index]
                              : ({} as PriceInfo)
                          }
                          active={route.routeId === selectedRoute.routeId}
                          disabled={saving}
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            <Box display={{ xs: 'block', md: 'none' }}>
              <Divider variant="middle" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="ticket-track-third-section">
            <Box component="section">
              <Box
                component="h2"
                mb={3}
                className={
                  'main-title-theme ' +
                  (!possiblePathes.route || !selectedRoute.routeId
                    ? 'dimmed'
                    : '')
                }
              >
                3. {t('Kernzone')}
              </Box>
              {!possiblePathes.route && (
                <Box component="p" mb={3} className="main-text-theme dimmed">
                  {t(
                    'Bitte geben Sie zuerst Streckendaten und Route an, bevor Sie die Kernzonen auswählen'
                  )}
                </Box>
              )}
              {possiblePathes.route && !selectedRoute.routeId && (
                <Box component="p" mb={3} className="main-text-theme dimmed">
                  {t(
                    'Bitte geben Sie zuerst die Route an, bevor Sie die Kernzonen auswählen'
                  )}
                </Box>
              )}
              {possiblePathes.route &&
                selectedRoute.routeId &&
                (selectedRoute.isCZFromPossible ||
                  selectedRoute.isCZToPossible) && (
                  <Box component="p" mb={3} className="main-text-theme">
                    {t(
                      'Folgende kostenpflichtige Kernzonen wurden erkannt Diese sind für den Umstieg in der Kernzone erforderlich'
                    )}
                  </Box>
                )}
              {possiblePathes.route &&
                selectedRoute.routeId &&
                !(
                  selectedRoute.isCZFromPossible || selectedRoute.isCZToPossible
                ) && (
                  <Box component="p" mb={3} className="main-text-theme">
                    {t(
                      'Für die Streckenauswahl haben wir keine kostenpflichtigen Kernzonen erkannt'
                    )}
                  </Box>
                )}
              {possiblePathes.route && selectedRoute.routeId && (
                <>
                  {selectedRoute.isCZFromPossible && (
                    <Box mb={3}>
                      <Box
                        component="p"
                        mb={1}
                        className="main-text-theme"
                        fontWeight="bold"
                      >
                        {t('Benötigen Sie ein Kernzonenticket für')}{' '}
                        {selectedRoute.startZone}? (
                        {formatCurrency(selectedPrice.priceCZFrom)}{' '}
                        {t('Aufpreis')})
                      </Box>
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="czFrom"
                            name="czFrom"
                            value={ticketRouteForm.values.czFrom}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              czFrom: string
                            ) => {
                              ticketRouteForm.setFieldValue('czFrom', czFrom);
                              setSelectedCoreZone({
                                ...selectedCoreZone,
                                czFrom,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="ja"
                              control={<Radio color="primary" size="small" />}
                              label={
                                t('Ja, ich benötige ein Kernzonenticket für') +
                                ' ' +
                                selectedRoute.startZone
                              }
                              name="czFrom"
                              color="primary"
                              classes={{ label: 'small-text-theme' }}
                              disabled={saving}
                            />
                            <FormControlLabel
                              value="nein"
                              control={<Radio color="primary" size="small" />}
                              label={
                                t(
                                  'Nein, ich benötige kein Kernzonenticket für'
                                ) +
                                ' ' +
                                selectedRoute.startZone
                              }
                              name="czFrom"
                              classes={{ label: 'small-text-theme' }}
                              disabled={saving}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                  {selectedRoute.isCZToPossible && (
                    <Box mb={3}>
                      <Box
                        component="p"
                        mb={1}
                        className="main-text-theme"
                        fontWeight="bold"
                      >
                        {t('Benötigen Sie ein Kernzonenticket für')}{' '}
                        {selectedRoute.endZone}? (
                        {formatCurrency(selectedPrice.priceCZTo)}{' '}
                        {t('Aufpreis')})
                      </Box>
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="czTo"
                            name="czTo"
                            value={ticketRouteForm.values.czTo}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                              czTo: string
                            ) => {
                              ticketRouteForm.setFieldValue('czTo', czTo);
                              setSelectedCoreZone({
                                ...selectedCoreZone,
                                czTo,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="ja"
                              control={<Radio color="primary" size="small" />}
                              label={
                                t('Ja, ich benötige ein Kernzonenticket für') +
                                ' ' +
                                selectedRoute.endZone
                              }
                              name="czTo"
                              color="primary"
                              classes={{ label: 'small-text-theme' }}
                              disabled={saving}
                            />
                            <FormControlLabel
                              value="nein"
                              control={<Radio color="primary" size="small" />}
                              label={
                                t(
                                  'Nein, ich benötige kein Kernzonenticket für'
                                ) +
                                ' ' +
                                selectedRoute.endZone
                              }
                              name="czTo"
                              classes={{ label: 'small-text-theme' }}
                              disabled={saving}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                  {((selectedRoute.isCZFromPossible &&
                    selectedRoute.isCZToPossible &&
                    ticketRouteForm.values.czFrom === 'nein' &&
                    ticketRouteForm.values.czTo === 'nein') ||
                    (selectedRoute.isCZFromPossible &&
                      !selectedRoute.isCZToPossible &&
                      ticketRouteForm.values.czFrom === 'nein') ||
                    (!selectedRoute.isCZFromPossible &&
                      selectedRoute.isCZToPossible &&
                      ticketRouteForm.values.czTo === 'nein')) && (
                    <ErrorBox
                      type={t('attention')}
                      text={
                        <Trans i18nKey="track_ticket_attention">
                          <a
                            className="small-link-theme"
                            href={coreZonesLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            heir
                          </a>
                        </Trans>
                      }
                    ></ErrorBox>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4} justify="flex-end" alignItems="center">
          <Grid item xs={12} md={4}>
            {routeError && (
              <Box mt={2}>
                <ErrorBox type={t('error')} text={t(routeError)} />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={{ xs: 'column-reverse', md: 'row !important' }}
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Box mr={{ md: 2 }} mb={2}>
                <Button
                  onClick={back}
                  className="btn-text"
                  variant="text"
                  color="secondary"
                  disabled={saving}
                  id="04-20"
                >
                  {t('Schritt zurück')}
                </Button>
              </Box>
              <Box mb={2} width={{ xs: '100%', md: '130px !important' }}>
                <Button
                  className="btn-theme btn-submit"
                  id="04-30"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    possiblePathesLoading ||
                    !selectedRoute.routeId ||
                    (selectedRoute.isCZFromPossible &&
                      !ticketRouteForm.values.czFrom) ||
                    (selectedRoute.isCZToPossible &&
                      !ticketRouteForm.values.czTo)
                  }
                >
                  {saving ? <CircularProgress color="inherit" /> : t('Weiter')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
