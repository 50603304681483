import React from "react";
import "./ticket-links.scoped.scss";
import { Box, Grid } from "@material-ui/core";
import { icons } from "../../statics/icons";
import { useTranslation } from "react-i18next";

export default function TicketLinks() {
  const [t] = useTranslation();
  return (
    <Box className="ticket-links-box" px={{ xs: 2.5, md: 5 }} py={5}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12} md={6} style={{ marginBottom: "25px" }}>
          <Box width={{ md: "80%" }}>
            <Box component="h2" className="main-title-theme" mb={"25px"}>
              {t("Hinweise")}
            </Box>
            <Box display="flex" alignItems="flex-start" mb={"25px"}>
              <img src={icons.infoGrey} alt="" className="ticket-link-img" />
              <Box component="p" className="main-text-theme">
                {t("Für eine")} <b>{t("monatliche Abbuchung")}</b>{" "}
                {t("verwenden Sie bitte die Zahlungsart SEPA online")}
              </Box>
            </Box>
            <Box display="flex" alignItems="flex-start" mb={"25px"}>
              <img src={icons.infoGrey} alt="" className="ticket-link-img" />
              <Box component="p" className="main-text-theme">
                {t(
                  "Die Bearbeitung der Karte kann je nach Zahlungsart bis zu vier Werktage in Anspruch nehmen"
                )}
                . {t("Der")} <b>{t("vorläufige Fahrschein")}</b>{" "}
                {t(
                  "wird nicht vom System automatisch versendet, sondern erst nach der Bearbeitung durch die Kundenbetreuung"
                )}
                .
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: "25px" }}>
          <Box width={{ md: "80%" }}>
            <Box component="h2" className="main-title-theme" mb={"25px"}>
              Links
            </Box>
            <Box display="flex" alignItems="center" mb={"27.5px"}>
              <img src={icons.link} alt="" className="ticket-link-img" />
              <a
                className="main-link-theme"
                href="https://www.ooevv.at/de/agb.html"
                target="_blank"
                rel="noreferrer"
              >
                {t("Allgemeine Geschäftsbedingungen")}
              </a>
            </Box>
            <Box display="flex" alignItems="center" mb={"27.5px"}>
              <img src={icons.link} alt="" className="ticket-link-img" />
              <a
                className="main-link-theme"
                href="https://www.ooevv.at/de/datenschutz.html"
                target="_blank"
                rel="noreferrer"
              >
                {t("Datenschutzerklärung")}
              </a>
            </Box>
            <Box display="flex" alignItems="center" mb={"27.5px"}>
              <img src={icons.download} alt="" className="ticket-link-img" />
              <a
                className="main-link-theme"
                href="https://www.ooevv.at/de/tickets-preise/klimaticket.html"
                target="_blank"
                rel="noreferrer"
                download
              >
                {t("Informationsseite KlimaTIcket OÖ")}
              </a>
            </Box>
            <Box display="flex" alignItems="center" mb={"27.5px"}>
              <img src={icons.download} alt="" className="ticket-link-img" />
              <a
                className="main-link-theme"
                href="https://www.ooevv.at/de/info-service/fahrgastrechte.html"
                target="_blank"
                rel="noreferrer"
                download
              >
                {t("Informationsseite Fahrgastrechte")}
              </a>
            </Box>
            <Box display="flex" alignItems="center" mb={"27.5px"}>
              <img src={icons.download} alt="" className="ticket-link-img" />
              <a
                className="main-link-theme"
                href="https://www.ooevv.at/de/links-downloads.html"
                target="_blank"
                rel="noreferrer"
                download
              >
                {t("Bestellformulare")}
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
