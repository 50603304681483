import React, { useEffect, useState } from 'react';
import './ticket-user-tab.scoped.scss';
import { Trans, useTranslation } from 'react-i18next';
import { TicketContext } from '../../store';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import moment from 'moment';
import 'moment/locale/de';
import MomentUtils from '@date-io/moment';
import { LangService } from '../../services/lang/lang.service';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneInput from 'react-phone-input-2';
import de from 'react-phone-input-2/lang/de.json';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { dateFormat, xml2JsonConfig } from '../../statics/main';
import { TicketsService } from '../../services/tickets/tickets.service';
import { University } from '../../types/University';
import { parseString } from 'xml2js';
import ErrorBox from '../error-box/error-box';
import { Invoice, User } from '../../types/User';
import {
  letterRegex,
  matrikelNrMaxChar,
  maxChar,
  numRegex,
} from '../../statics/validators';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { DataMillService } from '../../services/data-mill/data-mill.service';
import {
  calculateAge,
  handleSessionExpire,
  isSessionExpired,
  scrollToTabs,
} from '../../helpers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InfoIcon from '@material-ui/icons/Info';
import { config } from '../../config';
import { InfoDialog } from '../../dialogs/confirm-dialog/info-dialog';
import { Ticket } from '../../types/Ticket';
import { TICKET } from '../../enums/ticket';

export const TicketUserTab = () => {
  const [t] = useTranslation();
  const sessionStorageService = new SessionStorageService();
  const sessionID = sessionStorageService.getData('session');
  const user: User = sessionStorageService.getData('user') || new User();
  const countryCodeStored: string =
    sessionStorageService.getData('countryCode') || '';
  const birthDateError = 'Bitte gültiges Geburtsdatum angeben!';
  const postalCodeError = 'Bitte gültige Postleitzahl angeben!';
  const {
    setActiveTab,
    activeTab,
    setKlimaTickets,
    setKlimaTicket,
    setSelectedPrice,
    ticket,
    universities,
    validFrom,
    isExtendTicket,
    setIsTicketExtended,
    setDifferentInvoiceAddress,
    differentInvoiceAddress,
    setDisability,
    handicap,
    isTabDisabled,
    isExistingOrder,
  } = React.useContext(TicketContext);
  const langService = new LangService();
  const ticketsService = new TicketsService();
  const dataMill = new DataMillService();
  const locale = langService.getCurrentLang();
  moment.locale(locale);
  const [privacy, setPrivacy] = useState(false);
  const [
    universityUploadConfirmation,
    setUniversityUploadConfirmation,
  ] = useState(false);
  const [telInputFocus, setTelInputFocus] = useState(false);
  const [customerError, setCustomerError] = useState('');
  const [studentError, setStudentError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telError, setTelError] = useState('');
  const [birthDateSemesterError, setBirthDateSemesterError] = useState('');
  const [countryCode, setCountryCode] = useState(countryCodeStored);
  const [saving, setSaving] = useState(false);
  const [modalDisabilityOpen, setModalDisabilityOpen] = useState(false);
  const [modalResearchOpen, setModalResearchOpen] = useState(false);

  const back = () => {
    setActiveTab(activeTab - 1);
    scrollToTabs();
  };

  const next = () => {
    if (isExtendTicket) setIsTicketExtended(false);
    setActiveTab(activeTab + 1);
    scrollToTabs();
  };

  const resetErrors = () => {
    setCustomerError('');
    setTelError('');
    setEmailError('');
  };

  const handleMobilePhoneNumberStatus = (status: string) => {
    status === 'unknown'
      ? setTelError('unknown_mobile_number')
      : setTelError('invalid_mobile_number');
  };

  const handleEmailStatus = (status: string) => {
    status === 'unknown'
      ? setEmailError('unknown_email')
      : setEmailError('invalid_email');
  };

  const setCustomerData = async (values: User) => {
    resetErrors();
    const payload: any = {
      ...values,
      birthDate: moment(values.birthDate).utcOffset(0, true).toDate(),
      postalCode: Number(values.postalCode),
      sessionID,
    };
    payload.mobilephonenr = values.mobilephonenr?.includes('+')
      ? values.mobilephonenr
      : '+' + values.mobilephonenr;
    delete payload.universityId;
    delete payload.matrikelNr;
    sessionStorageService.setData('user', values);

    if (config.allowDataMill) {
      // Phone Number Validation
      if (countryCode) {
        const verifyMobileRes = await dataMill.verifyMobilePhoneNumber(
          values.mobilephonenr,
          countryCode
        );
        if (verifyMobileRes.valid !== '1') {
          handleMobilePhoneNumberStatus(verifyMobileRes.status);
          setSaving(false);
          return;
        }
      }

      // Email Validation
      const verifyEmailRes = await dataMill.verifyEmail(values.mail);
      if (verifyEmailRes.valid !== '1') {
        handleEmailStatus(verifyEmailRes.valid);
        setSaving(false);
        return;
      }
    }

    // Save Customer Data
    const customerRes = await ticketsService.setCustomerData(payload);
    if (customerRes.documentElement.textContent === 'false') {
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      if (!isSessionExpired(error)) {
        setCustomerError(error);
        setSaving(false);
      }
    } else {
      // Save Student Data
      if (ticket.isSemesterWorkflow) {
        setStudentData(values);
      } else if (ticket.ticketId === TICKET.KLIMA) {
        // if user selects klmiaTickets only, then call api
        await getClimateTickets();
        next();
      } else {
        next();
      }
    }
  };

  const setStudentData = async (values: User) => {
    const { universityId, matrikelNr } = values;
    const studentPayload = {
      sessionID,
      universityId,
      matrikelNr,
    };
    await ticketsService.setStudentData(studentPayload);
    if (studentError && universityUploadConfirmation) {
      sessionStorageService.setData('universityUploadConfirmation', true);
      next();
    } else {
      sessionStorageService.setData('universityUploadConfirmation', false);
      checkInscription(values);
    }
  };

  const checkInscription = (values: User) => {
    const university: University = universities.filter(
      (uni: University) => uni.id === values.universityId
    )[0];
    if (!university.identifier) {
      sessionStorageService.setData('universityUploadConfirmation', true);
      next();
      return;
    }
    const payload = {
      sessionID,
      birthDate: values.birthDate,
      matriculationNumber: values.matrikelNr,
      firstName: values.firstname,
      lastName: values.lastname,
      postalCode: values.postalCode,
      semester: `${moment(validFrom).year()}${
        moment(validFrom).month() > 8 ? 'W' : 'S'
      }`,
      uni: university.identifier,
    };
    ticketsService
      .checkInscription(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parseInscription(err, res)
        );
      });
  };

  const parseInscription = (err: Error | null, res: any) => {
    if (res) {
      const inscription =
        res['soap:Envelope']['soap:Body']['types:InscriptionServiceResult'];
      if (!inscription.isInscribed || inscription.errorDescription) {
        setSaving(false);
        if (
          inscription.errorDescription ===
          'Fehlende Berechtigung für angegebene Bildungseinrichtung.'
        ) {
          setStudentError(
            'Fehlende Berechtigung für angegebene Bildungseinrichtung'
          );
        } else {
          setStudentError(inscription.errorDescription);
        }
      } else {
        next();
      }
    }
  };

  const getClimateTickets = async () => {
    const payload = {
      age: calculateAge(customerInfoForm.values.birthDate, validFrom),
      handicap,
      sessionID,
    };
    ticketsService
      .getClimateTickets(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parseClimateTickets(err, res)
        );
      });
  };

  const parseClimateTickets = (err: Error | null, res: any) => {
    if (res) {
      const climateTickets: Ticket[] =
        res['soap:Envelope']['soap:Body']['types:Ticket'] || [];
      setKlimaTickets(climateTickets);
      if (!isExistingOrder) {
        // set Regional klimaticket as default.
        setKlimaTicket(climateTickets[0]);
        // reset price.
        setSelectedPrice({});
        sessionStorageService.setData('selectedTicket', climateTickets[0]);
      }
    }
  };

  const handlePostalCodeChange = (event: any, fieldName: string) => {
    const postalCode = event.target.value;
    if (!postalCode || numRegex.test(postalCode)) {
      customerInfoForm.setFieldValue(fieldName, postalCode);
    }
  };

  const handleMatrikelNrChange = (event: any) => {
    const matrikelNr = event.target.value;
    (!matrikelNr || numRegex.test(matrikelNr)) &&
      customerInfoForm.setFieldValue('matrikelNr', matrikelNr);
  };

  const handleBirthDateChange = (birthDate: MaterialUiPickersDate) => {
    setBirthDateSemesterError('');
    customerInfoForm.setFieldValue('birthDate', birthDate);
    if (ticket.isSemesterWorkflow && birthDate?.isValid) {
      const age = calculateAge(birthDate, validFrom);
      age >= 26 && setBirthDateSemesterError('invalid_birth_date_semester');
    }
  };

  const hanldeCountryCode = (countryCode: string) => {
    sessionStorageService.setData('countryCode', countryCode);
    setCountryCode(countryCode);
  };

  // TICKET SELECTION FORM
  const customerInfoValidationSchema = yup.object({
    sexcode: yup.string().required(),
    salutation: yup.string().max(maxChar),
    firstname: yup.string().required().matches(letterRegex, '').max(maxChar),
    lastname: yup.string().required().matches(letterRegex, '').max(maxChar),
    address: yup
      .string()
      .required()
      .max(maxChar)
      .matches(/\d/, 'pattern error'),
    postalCode: yup
      .string()
      .required()
      .min(4, 'too short')
      .max(4, 'too long')
      .matches(numRegex),
    city: yup.string().required().max(maxChar),
    birthDate: yup
      .date()
      .min(moment('1900-01-01').format('YYYY-MM-DD'))
      .max(moment())
      .required()
      .typeError(t('invalid_date_format')),
    mail: yup.string().required().email('invalid email'),
    mobilephonenr: yup.string().required(),
    ooevgNewsletter: yup.boolean(),
    dataTransferAccompanyingResearch: yup.boolean(),
    customerNr: yup.number(),
    universityId: ticket.isSemesterWorkflow
      ? yup.string().required()
      : yup.string(),
    matrikelNr: ticket.isSemesterWorkflow
      ? yup.string().required().matches(numRegex).max(matrikelNrMaxChar)
      : yup.string().matches(numRegex).max(matrikelNrMaxChar),
    invoiceCompanyName: differentInvoiceAddress
      ? yup.string().max(maxChar)
      : yup.string(),
    invoiceAddress: differentInvoiceAddress
      ? yup.string().required().max(maxChar).matches(/\d/, 'pattern error')
      : yup.string(),
    invoicePostalCode: differentInvoiceAddress
      ? yup
          .string()
          .required()
          .min(4, 'too short')
          .max(4, 'too long')
          .matches(numRegex)
      : yup.string(),
    invoiceCity: differentInvoiceAddress
      ? yup.string().required().max(maxChar)
      : yup.string(),
    invoiceUid: differentInvoiceAddress
      ? yup.string().max(maxChar)
      : yup.string(),
  });

  const customerInfoForm = useFormik({
    initialValues: { ...user },
    validationSchema: customerInfoValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (
        saving ||
        !customerInfoForm.isValid ||
        !privacy ||
        Boolean(birthDateSemesterError) ||
        (!universityUploadConfirmation && Boolean(studentError))
      ) {
        return;
      }
      const payload: any = { ...values };
      if (differentInvoiceAddress) {
        payload.invoiceFirstname = payload.firstname;
        payload.invoiceLastname = payload.lastname;
        payload.invoiceSalutation = payload.salutation;
        payload.invoiceSexcode = payload.sexcode;
      } else {
        Object.keys(new Invoice()).map((key: string) => delete payload[key]);
      }
      setSaving(true);
      setCustomerData(payload);
    },
  });

  useEffect(() => {
    if (user.birthDate) {
      handleBirthDateChange(moment(user.birthDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ticket-user-tab">
      <form
        onSubmit={customerInfoForm.handleSubmit}
        autoComplete="off"
        noValidate
        id="02-10"
      >
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={4} className="ticket-user-first-section">
            <Box component="section">
              <Box mb={1.5}>
                <FormControl
                  className="w-100"
                  required
                  error={Boolean(
                    customerInfoForm.errors.sexcode &&
                      customerInfoForm.submitCount
                  )}
                >
                  <InputLabel id="sexcode">{t('Anrede')}</InputLabel>
                  <Select
                    labelId="sexcode"
                    name="sexcode"
                    value={customerInfoForm.values.sexcode}
                    onChange={customerInfoForm.handleChange}
                    IconComponent={ExpandMoreIcon}
                  >
                    <MenuItem value="Male">{t('Herr')}</MenuItem>
                    <MenuItem value="Female">{t('Frau')}</MenuItem>
                    <MenuItem value="Diverse">{t('Divers')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('Titel')}
                  variant="standard"
                  name="salutation"
                  autoComplete="title"
                  value={customerInfoForm.values.salutation}
                  onChange={customerInfoForm.handleChange}
                  inputProps={{ maxLength: maxChar }}
                  error={Boolean(
                    customerInfoForm.errors.salutation &&
                      customerInfoForm.submitCount
                  )}
                />
              </Box>
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('Vorname')}
                  variant="standard"
                  name="firstname"
                  value={customerInfoForm.values.firstname}
                  onChange={customerInfoForm.handleChange}
                  error={Boolean(
                    customerInfoForm.errors.firstname &&
                      customerInfoForm.submitCount
                  )}
                  inputProps={{ maxLength: maxChar }}
                  required
                />
              </Box>
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('Familienname')}
                  variant="standard"
                  name="lastname"
                  value={customerInfoForm.values.lastname}
                  onChange={customerInfoForm.handleChange}
                  error={Boolean(
                    customerInfoForm.errors.lastname &&
                      customerInfoForm.submitCount
                  )}
                  inputProps={{ maxLength: maxChar }}
                  required
                />
              </Box>
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('Straße')}
                  variant="standard"
                  name="address"
                  value={customerInfoForm.values.address}
                  onChange={customerInfoForm.handleChange}
                  error={Boolean(
                    customerInfoForm.errors.address &&
                      customerInfoForm.submitCount
                  )}
                  inputProps={{ maxLength: maxChar }}
                  required
                />
              </Box>
              {Boolean(
                customerInfoForm.errors.address === 'pattern error' &&
                  customerInfoForm.submitCount
              ) && (
                <Box mb={1.5}>
                  <ErrorBox
                    type={t('error')}
                    text={t('Straße sollte mindestens eine Nummer enthalten')}
                  />
                </Box>
              )}
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('PLZ')}
                  variant="standard"
                  name="postalCode"
                  value={customerInfoForm.values.postalCode}
                  onChange={(e: any) => handlePostalCodeChange(e, 'postalCode')}
                  inputProps={{ minLength: 4, maxLength: 4 }}
                  error={Boolean(
                    customerInfoForm.errors.postalCode &&
                      customerInfoForm.submitCount
                  )}
                  required
                />
              </Box>
              {(customerError === postalCodeError ||
                Boolean(
                  customerInfoForm.errors.postalCode === 'too short' &&
                    customerInfoForm.submitCount
                )) && (
                <Box mb={1.5}>
                  <ErrorBox type={t('error')} text={t(postalCodeError)} />
                </Box>
              )}
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('Wohnort')}
                  variant="standard"
                  name="city"
                  value={customerInfoForm.values.city}
                  onChange={customerInfoForm.handleChange}
                  error={Boolean(
                    customerInfoForm.errors.city && customerInfoForm.submitCount
                  )}
                  inputProps={{ maxLength: maxChar }}
                  required
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="ticket-user-second-section">
            <Box component="section">
              <Box mb={1.5}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale={locale}
                >
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="standard"
                    format={dateFormat}
                    autoOk
                    name="birthDate"
                    value={customerInfoForm.values.birthDate}
                    onChange={(value: MaterialUiPickersDate) =>
                      handleBirthDateChange(value)
                    }
                    className="w-100"
                    label={t('Geburtsdatum')}
                    keyboardIcon={<EventOutlinedIcon />}
                    invalidDateMessage={t('invalid_date_format')}
                    maxDateMessage={t('invalid_birth_date')}
                    minDateMessage={t('invalid_birth_date')}
                    disableFuture
                    required
                    error={Boolean(
                      (customerInfoForm.errors.birthDate &&
                        customerInfoForm.values.birthDate) ||
                        (!customerInfoForm.values.birthDate &&
                          customerInfoForm.submitCount) ||
                        customerError === birthDateError ||
                        birthDateSemesterError
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              {customerError === birthDateError && (
                <Box mb={1.5}>
                  <ErrorBox type={t('error')} text={t(customerError)} />
                </Box>
              )}
              {birthDateSemesterError && (
                <Box mb={1.5}>
                  <ErrorBox
                    type={t('error')}
                    text={t(birthDateSemesterError)}
                  />
                </Box>
              )}
              <Box mb={1.5}>
                <TextField
                  className="w-100"
                  label={t('E-Mail Adresse')}
                  variant="standard"
                  name="mail"
                  value={customerInfoForm.values.mail}
                  onChange={customerInfoForm.handleChange}
                  error={Boolean(
                    (customerInfoForm.errors.mail || emailError) &&
                      customerInfoForm.submitCount
                  )}
                  inputProps={{ maxLength: maxChar }}
                  required
                  type="email"
                />
              </Box>
              {Boolean(
                customerInfoForm.errors.mail === 'invalid email' &&
                  customerInfoForm.submitCount
              ) && (
                <Box mb={1.5}>
                  <ErrorBox type={t('error')} text={t('enter_valid_email')} />
                </Box>
              )}
              {emailError && (
                <Box mb={1.5}>
                  <ErrorBox type={t('error')} text={t(emailError)} />
                </Box>
              )}
              <Box
                mb={1.5}
                className={
                  (customerInfoForm.errors.mobilephonenr || telError) &&
                  customerInfoForm.submitCount
                    ? 'phone-input-required'
                    : ''
                }
              >
                <label
                  className={
                    'custom-input-label ' +
                    (telInputFocus ? 'label-focused' : '')
                  }
                >
                  {t('Telefonnummer')} *
                </label>
                <PhoneInput
                  country="at"
                  countryCodeEditable={false}
                  onFocus={setTelInputFocus.bind(null, true)}
                  onBlur={setTelInputFocus.bind(null, false)}
                  localization={locale === 'de' ? de : undefined}
                  value={customerInfoForm.values.mobilephonenr}
                  preferredCountries={['at', 'de', 'ch', 'cz', 'it']}
                  onChange={(value: string, data: any) => {
                    hanldeCountryCode(data.countryCode);
                    customerInfoForm.setFieldValue('mobilephonenr', value);
                  }}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                />
              </Box>
              {telError && (
                <Box mb={1.5}>
                  <ErrorBox type={t('error')} text={t(telError)} />
                </Box>
              )}
              {customerInfoForm.values.customerNr ? (
                <Box mb={1.5}>
                  <TextField
                    className="w-100"
                    label={t('Kundennummer')}
                    variant="standard"
                    name="customerNr"
                    value={customerInfoForm.values.customerNr}
                    disabled
                  />
                </Box>
              ) : null}
              {ticket.isSemesterWorkflow && (
                <>
                  <Box mb={1.5}>
                    <FormControl
                      className="w-100"
                      required
                      error={Boolean(
                        customerInfoForm.errors.universityId &&
                          customerInfoForm.submitCount
                      )}
                    >
                      <InputLabel id="universityId">
                        {t('Universität')}
                      </InputLabel>
                      <Select
                        labelId="universityId"
                        name="universityId"
                        value={customerInfoForm.values.universityId}
                        onChange={(event: any) => {
                          setStudentError('');
                          customerInfoForm.setFieldValue(
                            'universityId',
                            event.target.value
                          );
                        }}
                        IconComponent={ExpandMoreIcon}
                      >
                        {universities.map(
                          (university: University, index: number) => {
                            return (
                              <MenuItem key={index} value={university.id}>
                                {university.name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('Matrikelnummer')}
                      variant="standard"
                      name="matrikelNr"
                      value={customerInfoForm.values.matrikelNr}
                      onChange={(event: any) => {
                        setStudentError('');
                        handleMatrikelNrChange(event);
                      }}
                      error={Boolean(
                        customerInfoForm.errors.matrikelNr &&
                          customerInfoForm.submitCount
                      )}
                      inputProps={{ maxLength: matrikelNrMaxChar }}
                      required
                    />
                  </Box>
                  {studentError && (
                    <Box mb={1.5}>
                      <ErrorBox type={t('error')} text={t(studentError)} />
                    </Box>
                  )}
                </>
              )}
              {ticket.ticketId === TICKET.KLIMA && (
                <>
                  <Box
                    mb={1.5}
                    display="flex"
                    alignItems="center"
                    left={'-10px'}
                    position="relative"
                  >
                    <Checkbox
                      checked={handicap}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setDisability(event.target.checked)
                      }
                      name="handicap"
                      color="primary"
                    />
                    <Box ml={{ md: 1 }} display="flex" alignItems="center">
                      {t('Ich bin mobilitätseingeschränkt')}{' '}
                      <InfoIcon
                        onClick={() => setModalDisabilityOpen(true)}
                        className="info-icon"
                      ></InfoIcon>
                    </Box>
                  </Box>
                  <InfoDialog
                    open={modalDisabilityOpen}
                    title={t('Ich bin mobilitätseingeschränkt')}
                    text={t('Ich bin mobilitätseingeschränkt popup')}
                    setClose={() => setModalDisabilityOpen(false)}
                  />
                  <Box
                    mb={1.5}
                    display="flex"
                    alignItems="center"
                    left={'-10px'}
                    position="relative"
                  >
                    <Checkbox
                      checked={differentInvoiceAddress}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setDifferentInvoiceAddress(event.target.checked)
                      }
                      name="differentInvoiceAddress"
                      color="primary"
                    />
                    <Box ml={{ md: 1 }}>
                      {t('Abweichende Rechnungsadresse')}
                    </Box>
                  </Box>
                </>
              )}
              {differentInvoiceAddress && (
                <>
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('Firmenname')}
                      variant="standard"
                      name="invoiceCompanyName"
                      value={customerInfoForm.values.invoiceCompanyName}
                      onChange={customerInfoForm.handleChange}
                      error={Boolean(
                        customerInfoForm.errors.invoiceCompanyName &&
                          customerInfoForm.submitCount
                      )}
                      inputProps={{ maxLength: maxChar }}
                    />
                  </Box>
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('Straße')}
                      variant="standard"
                      name="invoiceAddress"
                      value={customerInfoForm.values.invoiceAddress}
                      onChange={customerInfoForm.handleChange}
                      error={Boolean(
                        customerInfoForm.errors.invoiceAddress &&
                          customerInfoForm.submitCount
                      )}
                      inputProps={{ maxLength: maxChar }}
                      required
                    />
                  </Box>
                  {Boolean(
                    customerInfoForm.errors.invoiceAddress ===
                      'pattern error' && customerInfoForm.submitCount
                  ) && (
                    <Box mb={1.5}>
                      <ErrorBox
                        type={t('error')}
                        text={t(
                          'Straße sollte mindestens eine Nummer enthalten'
                        )}
                      />
                    </Box>
                  )}
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('PLZ')}
                      variant="standard"
                      name="invoicePostalCode"
                      value={customerInfoForm.values.invoicePostalCode}
                      onChange={(e: any) =>
                        handlePostalCodeChange(e, 'invoicePostalCode')
                      }
                      inputProps={{ minLength: 4, maxLength: 4 }}
                      error={Boolean(
                        customerInfoForm.errors.invoicePostalCode &&
                          customerInfoForm.submitCount
                      )}
                      required
                    />
                  </Box>
                  {Boolean(
                    customerInfoForm.errors.invoicePostalCode === 'too short' &&
                      customerInfoForm.submitCount
                  ) && (
                    <Box mb={1.5}>
                      <ErrorBox type={t('error')} text={t(postalCodeError)} />
                    </Box>
                  )}
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('Ort')}
                      variant="standard"
                      name="invoiceCity"
                      value={customerInfoForm.values.invoiceCity}
                      onChange={customerInfoForm.handleChange}
                      error={Boolean(
                        customerInfoForm.errors.invoiceCity &&
                          customerInfoForm.submitCount
                      )}
                      inputProps={{ maxLength: maxChar }}
                      required
                    />
                  </Box>
                  <Box mb={1.5}>
                    <TextField
                      className="w-100"
                      label={t('UID')}
                      variant="standard"
                      name="invoiceUid"
                      value={customerInfoForm.values.invoiceUid}
                      onChange={customerInfoForm.handleChange}
                      error={Boolean(
                        customerInfoForm.errors.invoiceUid &&
                          customerInfoForm.submitCount
                      )}
                      inputProps={{ maxLength: maxChar }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="ticket-user-third-section">
            <Box component="section" position="relative">
              <Box
                mb={4}
                display="flex"
                alignItems="center"
                left={'-10px'}
                position="relative"
              >
                <Checkbox
                  checked={privacy}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPrivacy(event.target.checked)
                  }
                  name="privacy"
                  color="primary"
                  className={
                    !privacy && customerInfoForm.submitCount
                      ? 'checkbox-required'
                      : ''
                  }
                />
                <Box ml={{ md: 1 }}>
                  {t(
                    'Ich bin mit der Speicherung der angegebenen Inhalten einverstanden und bestätige, dass ich die'
                  )}{' '}
                  <a
                    href="https://www.ooevv.at/de/datenschutz.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Datenschutzerklärung')}
                  </a>{' '}
                  {t('gelesen und zur Kenntnis genommen habe')}.
                </Box>
              </Box>

              <Box
                mb={4}
                display="flex"
                alignItems="center"
                left={'-10px'}
                position="relative"
              >
                <Checkbox
                  onChange={customerInfoForm.handleChange}
                  name="ooevgNewsletter"
                  checked={customerInfoForm.values.ooevgNewsletter}
                  color="primary"
                />
                <Box ml={{ md: 1 }}>
                  {t(
                    'Hiermit erkläre ich bis auf Widerruf mein Einverständnis, von der OÖ Verkehrsverbund Organisations GmbH Nfg'
                  )}{' '}
                  &amp; Co KG {t('weitere Informationen zu erhalten')}.
                </Box>
              </Box>

              <Box
                mb={4}
                display="flex"
                alignItems="center"
                left={'-10px'}
                position="relative"
              >
                <Checkbox
                  onChange={customerInfoForm.handleChange}
                  name="dataTransferAccompanyingResearch"
                  checked={
                    customerInfoForm.values.dataTransferAccompanyingResearch
                  }
                  color="primary"
                />
                <Box ml={{ md: 1 }} display="flex" alignItems="center">
                  {t(
                    'Ich möchte an der wissenschaftlichen Begleitstudie zum KlimaTicket teilnehmen'
                  )}{' '}
                  <InfoIcon
                    onClick={() => setModalResearchOpen(true)}
                    className="info-icon"
                  ></InfoIcon>
                </Box>
              </Box>
              <InfoDialog
                open={modalResearchOpen}
                title={t('climate ticket research')}
                text={
                  <Trans i18nKey="climate ticket research popup">
                    <a
                      className="main-link-theme"
                      href="https://www.infas.at/klimaticket"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.infas.at/klimaticket
                    </a>
                  </Trans>
                }
                setClose={() => setModalResearchOpen(false)}
              />
              {studentError && (
                <Box
                  mb={4}
                  display="flex"
                  alignItems="center"
                  left={'-10px'}
                  position="relative"
                >
                  <Checkbox
                    checked={universityUploadConfirmation}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setUniversityUploadConfirmation(event.target.checked)
                    }
                    name="universityUploadConfirmation"
                    color="primary"
                    className={
                      !universityUploadConfirmation &&
                      customerInfoForm.submitCount
                        ? 'checkbox-required'
                        : ''
                    }
                  />
                  <Box ml={{ md: 1 }}>
                    {t(
                      'Meine Daten sind korrekt, ich lade im nächsten Schritt meine Inskriptionsbestätigung hoch'
                    )}
                  </Box>
                </Box>
              )}
              {Boolean(
                (!customerInfoForm.isValid ||
                  !privacy ||
                  (studentError && !universityUploadConfirmation)) &&
                  customerInfoForm.submitCount
              ) && (
                <Box mb={4}>
                  <ErrorBox
                    type={t('error')}
                    text={t('complete_required_fields')}
                  ></ErrorBox>
                </Box>
              )}
              {customerError &&
                customerError !== postalCodeError &&
                customerError !== birthDateError && (
                  <Box mb={1.5}>
                    <ErrorBox type={t('error')} text={t(customerError)} />
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4} justify="flex-end" alignItems="center">
          {isExtendTicket && (
            <Grid item xs={12} md={4}>
              <Box className="main-text-theme error-text" textAlign="center">
                {t('Bitte überprüfen Sie die Eingaben')}
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column-reverse', md: 'row !important' }}
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Box mr={{ md: 2 }} mb={2}>
                {!isTabDisabled && (
                  <Button
                    onClick={back}
                    className="btn-text"
                    variant="text"
                    color="secondary"
                    disabled={saving}
                    id="02-20"
                  >
                    {t('Schritt zurück')}
                  </Button>
                )}
              </Box>
              <Box mb={2} width={{ xs: '100%', md: '130px !important' }}>
                <Button
                  className="btn-theme btn-submit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="02-30"
                >
                  {saving ? <CircularProgress color="inherit" /> : t('Weiter')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
